<template>

  <v-select v-model="lang" :items="languages" :label="$vuetify.lang.t('$vuetify.selectLanguage')"
            :menu-props="{ top: true, offsetY: true }" class="float-right"
            item-text="label" item-value="countryCode" return-object>
  </v-select>

</template>

<script>

export default {
  name: 'CoreToolbar',
  data () {
    return {
      lang: {
        label: 'English',
        countryCode: 'en'
      },
      languages: [
        {
          label: 'Français',
          countryCode: 'fr'
        },
        {
          label: 'English',
          countryCode: 'en'
        }
      ]
    }
  },
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = this.lang.countryCode
    }
  },
  watch: {
    lang () {
      this.changeLocale()
    }
  }
}
</script>

<style>
</style>
